export enum ESocialIconVariant {
  INSTAGRAM = 'ig',
  TELEGRAM = 'tg',
  VK = 'vk',
}

export interface ISocialIconVariantData {
  icon: string;
  link: string;
}

export type TSocialIconVariantsData = Record<ESocialIconVariant, ISocialIconVariantData>;

export interface ISocialIconProps {
  count: number;
  variant: ESocialIconVariant;
}
