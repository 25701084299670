<template>
  <footer class="footer-controller">
    <div class="footer-controller__container">
      <div class="footer-controller__information">
        <div class="footer-controller__information-about">
          <SvgoLogoFooter key="footer-logo" class="footer-controller__information-about--logo" />
          <div class="footer-controller__information-about--text">
            <p>{{ copyright }}</p>
            <p>{{ beneficiary }}. {{ address }}</p>
          </div>
        </div>
        <div v-if="!hideSocials" class="footer-controller__information-socials">
          <FooterControllerSocialIcon
            v-for="social in currentVariant.socials"
            :key="social.variant"
            :variant="social.variant"
            :count="social.count"
          />
        </div>
      </div>
      <div class="footer-controller__links">
        <NuxtLinkLocale v-for="item in links" :key="item.name" class="footer-controller__links-item" :to="item.href">
          {{ item.name }}
        </NuxtLinkLocale>
      </div>
      <FooterControllerPaymentMethods />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { EFooterProjects, type IFooterProps } from './FooterController.types';
import { ESocialIconVariant } from './SocialIcon/SocialIcon.types';

const {
  footer: { copyright: copyrightName, hideSocials },
  legalData: { beneficiary, address },
  project,
} = useProjectSettings();

const { t } = useI18n();

const CURRENT_YEAR = new Date().getFullYear();

const props = withDefaults(defineProps<IFooterProps>(), {
  variant: EFooterProjects.DOTA,
});
const { variant } = toRefs(props);

const copyright = computed(() => t('footer.copyright', { company: copyrightName, year: CURRENT_YEAR }));

const variants = {
  [EFooterProjects.DOTA]: {
    socials: [
      {
        count: 110000,
        variant: ESocialIconVariant.VK,
      },
      {
        count: 17463,
        variant: ESocialIconVariant.TELEGRAM,
      },
      {
        count: 8986,
        variant: ESocialIconVariant.INSTAGRAM,
      },
    ],
  },
  [EFooterProjects.CS]: {
    socials: [
      {
        count: 100762,
        variant: ESocialIconVariant.VK,
      },
      {
        count: 12285,
        variant: ESocialIconVariant.TELEGRAM,
      },
      {
        count: 8253,
        variant: ESocialIconVariant.INSTAGRAM,
      },
    ],
  },
};

const rulesLink = computed(() => {
  const rules = {
    'dota-global': ROUTING.FOOTER_PAGES.PRIVACY_POLICY,
  };

  return rules[project as keyof typeof rules] ?? ROUTING.FOOTER_PAGES.EVENT_CONDITION;
});

const links = computed(() => {
  return [
    {
      href: ROUTING.FOOTER_PAGES.CONTACTS,
      name: t('footer.links.about'),
    },
    {
      href: ROUTING.FOOTER_PAGES.USER_AGREEMENT,
      name: t('footer.links.agreement'),
    },
    {
      href: rulesLink.value,
      name: t('footer.links.rules'),
    },
    {
      href: ROUTING.FOOTER_PAGES.COOKIES,
      name: t('footer.links.policy'),
    },
    {
      href: ROUTING.FAQ.MAIN,
      name: t('footer.links.faq'),
    },
  ];
});

const currentVariant = computed(() => variants[variant.value]);
</script>

<style scoped lang="scss" src="./FooterController.scss" />
