<template>
  <SkeletonComponentLoader>
    <section v-if="!statisticsLoaded" class="footer-controller-skeleton">
      <SkeletonBaseForeground
        v-for="i in 4"
        :key="i"
        :width="item.width"
        :height="item.height"
        :border-radius="item.border"
      />
    </section>
    <FooterController v-else class="layout__footer" />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { useStatisticStore } from '~/store/statistic/statistic.store';

const statisticStore = useStatisticStore();

const { isLoaded: statisticsLoaded } = storeToRefs(statisticStore);

const changeMD = GlobalUtils.Media.changeByMedia('md');
const changeXL = GlobalUtils.Media.changeByMedia('xl');

const item = computed(() => ({
  border: 8,
  height: changeMD(144, 60),
  width: changeXL(224, changeMD(154, 328)),
}));
</script>

<style scoped lang="scss" src="./FooterController.skeleton.scss" />
